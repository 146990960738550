import React from 'react';
import { CircularProgress } from '@mui/material';
import {
  LayoutChildrenStyled, LayoutContentStyled, LayoutStyled,
} from '../../apps/Apps.styled';
import { theme } from '../../styles/theme';

const FullPageLoader: React.FC = () => (
  <LayoutStyled>
    <LayoutContentStyled>
      <LayoutChildrenStyled isCentered>
        <CircularProgress sx={{
          color: theme.primary,
        }}
        />
      </LayoutChildrenStyled>
    </LayoutContentStyled>
  </LayoutStyled>
);

export default FullPageLoader;
