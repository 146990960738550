import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import { theme } from '../styles/theme';
import { font300, h3CaptionStyle } from '../styles/typography';

export const PageTitleWrapper = styled.div`
  flex: 2;
`;

export const AppTitle = styled.p`
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${theme.secondary50};
  ${h3CaptionStyle};
  ${font300};

  @media (max-width: ${breakpoints.tabletPx}) {
    margin-bottom: 1.5em;
  }
`;

export const AppsLayoutHeader = styled.div<{
  position?: 'absolute' | 'fixed' | 'static' | 'relative';
  isSmallScreen: boolean;
}>`
  display: flex;
  align-items: center;
  height: 3.125em;
  position: ${({ position }) => position || 'inherit'};
  width: 100%;
  padding-left: 5.9375em;
  padding-right: 1.875em;
  box-sizing: border-box;
  background-color: ${theme.bodyBg};
  z-index: 9;

  ${({ isSmallScreen }) => isSmallScreen
    && `
    box-sizing: content-box;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5em;
    justify-content: space-between;
    padding-bottom: 0.5em;
  `}
`;

export const LayoutStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LayoutContentStyled = styled.div`
  width: 100%;
`;

export const LayoutChildrenStyled = styled.div<{
  isCentered?: boolean;
  isFixedLayout?: boolean;
}>`
  display: flex;
  justify-content: ${({ isCentered }) => isCentered && 'center'};

  ${({ isFixedLayout }) => (isFixedLayout
    ? `
  padding: 3.125em 0.4em 1.875em 5.8em;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  `
    : `
  height: calc(100vh - 50px);
  padding: 0.2em 2.5em;
  `)};

  @media (max-width: ${breakpoints.laptopSmallPx}) {
    padding-left: 0.375em;
    padding-top: 1.31em;
    padding-right: 0.375em;
  }
`;
