import { lazy } from 'react';
import { RouterRoute } from '../../../router/router.types';
import PasswordAuthAppPaths from './PasswordAuth.paths';

const LoginPage = lazy(() => import('../../../pages/Login/LoginPage'));
const ForgotPasswordPage = lazy(() => import('../../../pages/ForgotPassword/ForgotPasswordWrapper'));
const PasswordPage = lazy(() => import('../../../pages/Password/PasswordWrapper'));

const passwordAuthRoutes: RouterRoute<PasswordAuthAppPaths>[] = [
  {
    path: PasswordAuthAppPaths.Login,
    element: LoginPage,
    isLazy: true,
    isProtected: false,
  },
  {
    path: PasswordAuthAppPaths.ForgotPassword,
    element: ForgotPasswordPage,
    isLazy: true,
    isProtected: false,
  },
  {
    path: PasswordAuthAppPaths.ChangePassword,
    element: PasswordPage,
    isLazy: true,
    isProtected: false,
  },
];

export default passwordAuthRoutes;
