import { lazy } from 'react';
import InsightsAppPaths from './Insights.paths';
import { RouterRoute } from '../../../router/router.types';

const InsightsPage = lazy(
  () => import('../../../pages/Insights/InsightsPageWrapper'),
);
const ExplorerPage = lazy(
  () => import('../../../pages/Insights/Explorer/ExplorerPageWrapper'),
);
const ConfigurePage = lazy(
  () => import('../../../pages/Insights/Configure/ConfigurePageWrapper'),
);
const WidgetPage = lazy(() => import('../../../pages/Widget/WidgetPage'));
const SettingsPage = lazy(() => import('../../../pages/Insights/Settings/SettingsPage'));
const ProfilePage = lazy(() => import('../../../pages/Insights/Profile/ProfilePage'));
const ReportsPage = lazy(() => import('../../../pages/Insights/Reports/ReportsPage'));
const ServicePage = lazy(
  () => import('../../../pages/Service/ServicePageWrapper'),
);
const IndoorTemperature = lazy(
  () => import(
    '../../../components/Widgets/Insights/ThermalComfort/IndoorTemperature/Big/IndoorTemperatureWrapper'
  ),
);
const ThermalComfortWidgets = lazy(
  () => import(
    '../../../components/Widgets/Insights/ThermalComfort/ThermalComfortWidgets'
  ),
);
const AirQualityWidgets = lazy(
  () => import('../../../components/Widgets/Insights/AirQuality/AirQualityWidgets'),
);
const RelativeHumidity = lazy(
  () => import(
    '../../../components/Widgets/Insights/AirQuality/RelativeHumidity/Big/RelativeHumidityWrapper'
  ),
);
const SpaceUtilizationWidgets = lazy(
  () => import(
    '../../../components/Widgets/Insights/SpaceUtilization/SpaceUtilizationWidgets'
  ),
);
const DeskOccupancy = lazy(
  () => import(
    '../../../components/Widgets/Insights/SpaceUtilization/DeskOccupancy/Big/DeskOccupancyWrapper'
  ),
);
const VisualComfortWidgets = lazy(
  () => import(
    '../../../components/Widgets/Insights/VisualComfort/VisualComfortWidgets'
  ),
);
const LightLevels = lazy(
  () => import(
    '../../../components/Widgets/Insights/VisualComfort/LightLevels/Big/LightLevelsWrapper'
  ),
);
const PerfAudit = lazy(() => import('../../../pages/Insights/PerfAudit/PerfAuditWrapper'));

const ControlsPage = lazy(() => import('../../../pages/Insights/Controls/ControlsWrapper'));

const insightsRoutes: RouterRoute<InsightsAppPaths>[] = [
  {
    path: InsightsAppPaths.Home,
    element: InsightsPage,
    isLazy: true,
    isIndex: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.Widgets,
    element: WidgetPage,
    isLazy: true,
    isProtected: true,
    nested: [
      {
        path: InsightsAppPaths.ThermalComfort,
        element: ThermalComfortWidgets,
        isLazy: true,
        isProtected: true,
        nested: [
          {
            path: InsightsAppPaths.IndoorTemperature,
            element: IndoorTemperature,
            isLazy: true,
            isProtected: true,
          },
        ],
      },
      {
        path: InsightsAppPaths.AirQuality,
        element: AirQualityWidgets,
        isLazy: true,
        isProtected: true,
        nested: [
          {
            path: InsightsAppPaths.RelativeHumidity,
            element: RelativeHumidity,
            isLazy: true,
            isProtected: true,
          },
        ],
      },
      {
        path: InsightsAppPaths.VisualComfort,
        element: VisualComfortWidgets,
        isLazy: true,
        isProtected: true,
        nested: [
          {
            path: InsightsAppPaths.LightLevels,
            element: LightLevels,
            isLazy: true,
            isProtected: true,
          },
        ],
      },
      {
        path: InsightsAppPaths.SpaceUtilization,
        element: SpaceUtilizationWidgets,
        isLazy: true,
        isProtected: true,
        nested: [
          {
            path: InsightsAppPaths.DeskOccupancy,
            element: DeskOccupancy,
            isLazy: true,
            isProtected: true,
          },
        ],
      },
    ],
  },
  {
    path: InsightsAppPaths.Explorer,
    element: ExplorerPage,
    isLazy: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.Configure,
    element: ConfigurePage,
    isLazy: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.Controls,
    element: ControlsPage,
    isLazy: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.Settings,
    element: SettingsPage,
    isProtected: true,
    isLazy: true,
  },
  {
    path: InsightsAppPaths.Profile,
    element: ProfilePage,
    isLazy: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.Reports,
    element: ReportsPage,
    isLazy: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.Service,
    element: ServicePage,
    isLazy: true,
    isProtected: true,
  },
  {
    path: InsightsAppPaths.PerfAudit,
    isLazy: true,
    isProtected: true,
    element: PerfAudit,
  },
];

export default insightsRoutes;
