import i18n from '../../locales/i18n';
import { ErrorOptions } from './OTPAuthProvider.types';

// eslint-disable-next-line import/prefer-default-export
export const errorMessages: ErrorOptions = {
  400: i18n.t('errors:badRequest'),
  401: i18n.t('errors:unAuthorized'),
  403: i18n.t('errors:forbidden'),
  404: i18n.t('errors:noRequest'),
  500: i18n.t('somethingWentWrong'),
};
