import { lazy } from 'react';
import { RouterRoute } from '../../../router/router.types';
import OTPAuthAppPaths from './OTPAuth.paths';

const LoginEmailPage = lazy(() => import('../../../pages/OTPLogin/OTPEmailPage'));
const LoginOTPPage = lazy(() => import('../../../pages/OTPLogin/OTPLoginPage'));

const otpAuthRoutes: RouterRoute<OTPAuthAppPaths>[] = [
  {
    path: OTPAuthAppPaths.LoginEmail,
    element: LoginEmailPage,
    isLazy: true,
    isProtected: false,
  },
  {
    path: OTPAuthAppPaths.LoginOTP,
    element: LoginOTPPage,
    isLazy: true,
    isProtected: false,
  },
];

export default otpAuthRoutes;
