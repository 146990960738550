import { lazy } from 'react';
import { RouterRoute } from '../../../router/router.types';
import QRAuthAppPaths from './QRAuth.paths';

const QRAuthPage = lazy(() => import('../../../pages/QRAuth/QRAuthPageWrapper'));

const qrAuthRoutes: RouterRoute<QRAuthAppPaths>[] = [
  {
    path: QRAuthAppPaths.Login,
    element: QRAuthPage,
    isLazy: true,
    isProtected: false,
  },
];

export default qrAuthRoutes;
