import axios from 'axios';
import { ORCHESTRATOR_URL } from '../../constants';
import { OTPCredentials } from '../../providers/OTPAuthProvider/OTPAuthProvider.types';

const baseURL = `${ORCHESTRATOR_URL}/auth/otp`;

/**
 * API calls to authenticate user using OTP
 */
const otpAuthApi = {
  login: async ({ email } : Pick<OTPCredentials, 'email'>) => axios
    .post(`${baseURL}/request`, {
      email,
    })
    .then((result) => result.data),
  sendOTP: async (data: OTPCredentials) => axios.post(`${baseURL}/login`, data)
    .then((result) => result.data),
};

export default otpAuthApi;
