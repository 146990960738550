import axios from 'axios';
import { ORCHESTRATOR_URL } from '../../constants';
import getProjectName from '../../utils/getProjectName';

const axiosInstance = axios.create({
  baseURL: ORCHESTRATOR_URL,
  withCredentials: true,
  headers: {
    'x-project-slug': getProjectName,
  },
});

export default axiosInstance;
