// eslint-disable-next-line import/prefer-default-export
export const theme = {
  transparent: 'transparent',
  primary: '#42BECA',
  primary0: '#F5FCFC',
  primary30: '#6DD7D3',
  primary50: '#5FCFD0',
  secondary50: '#043D3A',
  secondary30: '#759492',
  secondary10: '#E6ECEB',
  tertiary: '#64667D',
  tertiary10: '#C1C2CB',
  tertiary30: '#F5F5F6',
  grey1: '#838597',
  grey2: '#A3A5B9',
  grey3: '#C4C4C4',
  grey4: '#F3F3F3',
  lightGreen: '#E1F2F2',
  midGreen: '#D0EFF2',
  iconsBG: '#E6ECEB',
  white: '#fff',
  error: '#E61313',
  black: '#000',
  fontWeights: {
    100: 100,
    300: 300,
    500: 500,
    700: 700,
    900: 900,
  },
  fontFamilies: {
    100: 'MuseoSans-100',
    300: 'MuseoSans-300',
    500: 'MuseoSans-500',
    700: 'MuseoSans-700',
    900: 'MuseoSans-900',
  },
  // static colors from the charts start
  gradient1: 'linear-gradient(161.08deg, #D9E6EB 6.53%, rgba(217, 230, 235, 0.2) 87.24%)',
  orange1: '#F3B282',
  cloudyBg: '#64667d33',
  sunnyBg: '#6dd7d340',
  coldBg: '#227dbf4d',
  nightBg: '#304a6280',
  yellow: '#F7B51F',
  yellow1: '#A16F0B',
  lux2: '#37572d',
  lux3: '#4f6429',
  lux4: '#687123',
  lux5: '#9c8b16',
  lux6: '#b49913',
  lux7: '#cda50b',
  lux8: '#e6b307',
  mostlySunny: '#52bccda1',
  successGreen: '#83D182',
  bodyBg: '#dae6ec',
  pink: '#D52BFF',
  floorBg: '#f5f9fa',
  // static colors from the charts end
};
