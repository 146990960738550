import { css } from 'styled-components';

/**
 * H1
 */
export const heading1Style = css`
  font-size: 0.75em;
  line-height: 1em;
`;

/**
 * H2
 */
export const heading2Style = css`
  font-size: 0.875em;
  line-height: 1em;
`;

/**
 * H3
 */
export const heading3Style = css`
  font-size: 1em;
  line-height: 1.5em;
`;

/**
 * H4
 */
export const heading4Style = css`
  font-size: 1.125em;
  line-height: 1.625em;
`;

/**
 * H6
 */
export const heading6Style = css`
  font-size: 1.5em;
  line-height: 2em;
`;

/**
 * H8
 */
export const heading8Style = css`
  font-size: 2em;
  line-height: 2.625em;
`;

export const caption1Style = css`
  font-size: 0.75em;
  line-height: 1em;
  letter-spacing: -0.0075em;
`;

export const caption2Style = css`
  font-size: 0.6875em;
  line-height: 1em;
  letter-spacing: -0.006875em;
`;

/**
 * Body 1
 */
export const bodyBoldStyle = css`
  font-size: 1em;
  line-height: 1.25em;
  letter-spacing: -0.01em;
`;

/**
 * Body 2
 */
export const bodyLightStyle = css`
  font-size: 0.875em;
  line-height: 1em;
  letter-spacing: -0.00875em;
`;

/**
 * Button
 */
export const buttonStyle = css`
  font-size: 0.875;
  line-height: 1.5em;
`;

export const textStyle = css`
  font-size: 0.8125em;
  line-height: 1em;
  letter-spacing: -0.008125em;
`;

export const h5CaptionStyle = css`
  font-size: 1.375em;
  line-height: 1.875em;
  letter-spacing: 0.275em;
  text-transform: uppercase;
`;

export const h3CaptionStyle = css`
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export const bodyExtraSmallStyle = css`
  font-size: 0.625em;
  line-height: 0.875em;
`;

export const font300 = css`
  font-family: 'MuseoSans-300';
  font-weight: 300;
`;

export const font500 = css`
  font-family: 'MuseoSans-500';
  font-weight: 500;
`;

export const font700 = css`
  font-family: 'MuseoSans-700';
  font-weight: 700;
`;

export const font100 = css`
  font-family: 'MuseoSans-100';
  font-weight: 100;
`;

export const font900 = css`
  font-family: 'MuseoSans-900';
  font-weight: 900;
`;
