import i18n from '../locales/i18n';

function assertsEnvExists(key?: string): asserts key is string {
  if (!key || !key.length) {
    throw new Error(i18n.t('errors:noLocalStorageKey'));
  }
}

const getEnv = (key: string) => {
  assertsEnvExists(key);

  return process.env[key];
};

export default getEnv;
