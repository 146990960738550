import './styles/index.css';
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import queryClient from './api/reactQuery/queryClient';
import i18n from './locales/i18n';
import { PasswordAuthProvider } from './providers/PasswordAuthProvider/PasswordAuth.provider';
import AppsLoader from './apps/AppsLoader';
import { OTPAuthProvider } from './providers/OTPAuthProvider/OTPAuth.provider';
import { AuthProvider } from './providers/AuthProvider/Auth.provider';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <PasswordAuthProvider>
            <OTPAuthProvider>
              <AppsLoader />
            </OTPAuthProvider>
          </PasswordAuthProvider>
        </AuthProvider>

      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;
