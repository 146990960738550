import { lazy } from 'react';
import AdminAppPaths from './Admin.paths';
import { RouterRoute } from '../../../router/router.types';

const AdminPage = lazy(() => import('../../../pages/Admin/AdminPage'));
const AdminMonitor = lazy(() => import('../../../pages/Monitoring/MonitoringPage'));
const adminRoutes: RouterRoute<AdminAppPaths>[] = [
  {
    path: AdminAppPaths.Home,
    element: AdminPage,
    isLazy: false,
    isIndex: true,
    isProtected: true,
  },
  {
    path: AdminAppPaths.Monitoring,
    element: AdminMonitor,
    isLazy: false,
    isIndex: true,
    isProtected: true,
  },
];

export default adminRoutes;
