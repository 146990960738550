import { useState } from 'react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import otpAuthApi from '../../api/otpAuth/otpAuth.api';
import {
  LoginDataType,
  OTPCredentials,
  OTPDataResponse,
} from './OTPAuthProvider.types';
import { errorMessages } from './utils';

/**
 * Validate if the token is present in the response of the request
 * @param data - token string
 */
export function assertTokenResponse(
  data?: OTPDataResponse,
): asserts data is OTPDataResponse {
  if (!data?.token || !data.expiresIn) {
    throw new Error('Something went wrong! Try again in sometime.');
  }
}

/**
 * Validate if the email is preseny in the response of the request
 * @param data - email string
 */
function assertEmailResponse(
  data?: LoginDataType,
): asserts data is LoginDataType {
  if (!data?.email) {
    throw new Error('Something went wrong! Try again in sometime.');
  }
}

/**
 * OTP Auth Provider sets token to the localstorage and validated if user is signed in with the OTP
 */
const useOTPAuthProvider = () => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(['otplogin']);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const setCodeSent = (data: LoginDataType) => {
    window.localStorage.setItem('email', data.email);
    setIsCodeSent(true);
    window.dispatchEvent(new Event('storage'));
    setErrorMsg(undefined);
  };

  const getData = (token: string) => {
    window.localStorage.clear();
    window.localStorage.setItem('token', token);
    window.dispatchEvent(new Event('storage'));
  };

  const login = (incomingValues: LoginDataType) => {
    queryClient
      .fetchQuery(['otplogin'], () => otpAuthApi.login(incomingValues))
      .then((res) => {
        assertEmailResponse(res);
        setCodeSent(res);
      })
      .catch((err) => {
        setErrorMsg(errorMessages[err.response?.status] ?? err.message);
      });
  };

  const sendOTP = (incomingValues: OTPCredentials) => {
    queryClient
      .fetchQuery(['otplogin'], () => otpAuthApi.sendOTP(incomingValues))
      .then((res) => {
        assertTokenResponse(res);
        getData(res.token);
      })
      .catch((err) => {
        setErrorMsg(errorMessages[err.response.status] ?? err.message);
      });
  };

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage'));
  };

  return {
    login,
    logout,
    isLoading: !!isFetching,
    isError: Boolean(errorMsg),
    sendOTP,
    isCodeSent,
    errorMsg,
  };
};

export default useOTPAuthProvider;
