import React, { useContext } from 'react';
import { PasswordAuthContextProps, PasswordAuthContextType } from './PasswordAuthProvider.types';
import usePasswordAuthProvider from './usePasswordAuthProvider';

export const PasswordAuthContext = React.createContext<PasswordAuthContextType>(
  {
  } as PasswordAuthContextType,
);

export function PasswordAuthProvider({ children }: PasswordAuthContextProps) {
  const hook = usePasswordAuthProvider();

  return <PasswordAuthContext.Provider value={hook}>{children}</PasswordAuthContext.Provider>;
}

export const usePasswordAuthContext = () => useContext(PasswordAuthContext);
