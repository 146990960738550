import React, { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import FullPageLoader from '../pages/FullPageLoader/FullPageLoader';
import AppsSchema from './Apps.schema';
import NotFoundPage from '../pages/NotFound/NotFoundPage';

const AppsLoader = () => {
  const rootPath = useMemo(() => window.location.pathname.split('/')[1], []);

  useEffect(() => {
    if (rootPath.length === 0) {
      window.location.replace('insights');
    }
  }, []);

  const findAppByPath = useMemo(
    () => AppsSchema.find((val) => val.basename === rootPath),
    [rootPath],
  );

  if (!findAppByPath) {
    return (
      <NotFoundPage />
    );
  }

  return (
    <BrowserRouter basename={findAppByPath.basename}>
      <React.Suspense fallback={<FullPageLoader />}>
        {React.createElement(findAppByPath.app)}
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppsLoader;
