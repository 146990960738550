import { lazy } from 'react';
import CommissionAppPaths from './Commission.paths';
import { RouterRoute } from '../../../router/router.types';

const CommissionHome = lazy(
  () => import('../../../pages/Commission/CommissionPage'),
);

const CommissionCommission = lazy(
  () => import('../../../containers/QRCommission/Commission/CommissionWrapper'),
);

const CommissionScanQR = lazy(
  () => import('../../../containers/QRCommission/Commission/ScanQR/ScanQRWrapper'),
);

const commissionRoutes: RouterRoute<CommissionAppPaths>[] = [
  {
    path: CommissionAppPaths.Home,
    element: CommissionHome,
    isLazy: true,
    isIndex: true,
    isProtected: true,
  },
  {
    path: CommissionAppPaths.Commission,
    element: CommissionCommission,
    isLazy: true,
    isIndex: true,
    isProtected: true,
  },
  {
    path: CommissionAppPaths.Scan,
    element: CommissionScanQR,
    isLazy: true,
    isIndex: true,
    isProtected: true,
  },
];

export default commissionRoutes;
