export enum TemperatureComfortThreshold {
  Min = 21,
  Max = 25,
}

export enum PastTemperatureChartDataKeys {
  OutdoorLine = 'outdoor-line',
  IndoorLine = 'indoor-line',
  ComfortLine = 'comfort-line',
  EmptyArea = 'empty-area',
  ComfortArea = 'comfort-area',
  ComfortGradient = 'gradient-comfort-area',
}
