import { lazy } from 'react';
import ControlAppPaths from './Control.paths';
import { RouterRoute } from '../../../router/router.types';

const ControlHome = lazy(
  () => import('../../../pages/Control/ControlPageWrapper'),
);

const WrongNetwork = lazy(
  () => import('../../../pages/WrongNetwork/WrongNetwork'),
);

const LoggedOut = lazy(
  () => import('../../../pages/LoggedOut/LoggedOut'),
);

const controlRoutes: RouterRoute<ControlAppPaths>[] = [
  {
    path: ControlAppPaths.Home,
    element: ControlHome,
    isLazy: true,
    isIndex: true,
    isProtected: true,
  },
  {
    path: ControlAppPaths.WrongNetwork,
    element: WrongNetwork,
    isLazy: true,
    isIndex: true,
    isProtected: false,
  },
  {
    path: ControlAppPaths.LoggedOut,
    element: LoggedOut,
    isLazy: true,
    isIndex: true,
    isProtected: false,
  },
];

export default controlRoutes;
