import { lazy } from 'react';
import passwordAuthRoutes from './PasswordAuth/router/PasswordAuth.routes';
import overviewRoutes from './Overview/router/Overview.routes';
import insightsRoutes from './Insights/router/Insights.routes';
import { AppsType } from './Apps.types';
import adminRoutes from './Admin/router/Admin.routes';
import otpAuthRoutes from './OTPAuth/router/OTPAuth.routes';
import commissionRoutes from './Commission/router/Commission.routes';
import qrAuthRoutes from './QRAuth/router/QRAuth.routes';
import controlRoutes from './Control/router/Control.routes';

// eslint-disable-next-line import/no-cycle
const OverviewApp = lazy(() => import('./Overview/Overview.app'));
// eslint-disable-next-line import/no-cycle
const InsightsApp = lazy(() => import('./Insights/Insights.app'));
// eslint-disable-next-line import/no-cycle
const PasswordAuthApp = lazy(() => import('./PasswordAuth/PasswordAuth.app'));
// eslint-disable-next-line import/no-cycle
const AdminApp = lazy(() => import('./Admin/Admin.app'));
// eslint-disable-next-line import/no-cycle
const CommissionApp = lazy(() => import('./Commission/Commission.app'));
// eslint-disable-next-line import/no-cycle
const OTPAuthApp = lazy(() => import('./OTPAuth/OTPAuth.app'));
// eslint-disable-next-line import/no-cycle
const QRAuthApp = lazy(() => import('./QRAuth/QRAuth.app'));
// eslint-disable-next-line import/no-cycle
const ControlApp = lazy(() => import('./Control/Control.app'));

const AppsSchema: AppsType[] = [
  {
    basename: 'password',
    app: PasswordAuthApp,
    routes: passwordAuthRoutes,
  },
  {
    basename: 'bold',
    app: OverviewApp,
    routes: overviewRoutes,
  },
  {
    basename: 'insights',
    app: InsightsApp,
    routes: insightsRoutes,
  },
  {
    basename: 'admin',
    app: AdminApp,
    routes: adminRoutes,
  },
  {
    basename: 'commission',
    app: CommissionApp,
    routes: commissionRoutes,
  },
  {
    basename: 'otp',
    app: OTPAuthApp,
    routes: otpAuthRoutes,
  },
  {
    basename: 'qr-code',
    app: QRAuthApp,
    routes: qrAuthRoutes,
  },
  {
    basename: 'control',
    app: ControlApp,
    routes: controlRoutes,
  },
];

export default AppsSchema;
