import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';

i18n.use(initReactI18next)
  .init({
    debug: false,
    initImmediate: false,
    fallbackLng: 'en',
    preload: ['en'],
    ns: ['common'],
    resources: {
      en,
    },
  });

export default i18n;
