import { lazy } from 'react';
import OverviewAppPaths from './Overview.paths';
import { RouterRoute } from '../../../router/router.types';

const OverviewPage = lazy(() => import('../../../pages/Overview/Overview'));

const overviewRoutes: RouterRoute<OverviewAppPaths>[] = [
  {
    path: OverviewAppPaths.Home,
    element: OverviewPage,
    isLazy: true,
    isProtected: false,
  },
];

export default overviewRoutes;
