import { Credentials } from '../../providers/PasswordAuthProvider/PasswordAuthProvider.types';
import axios from '../axios/axiosInstance';

/**
 * API call to authenticate user using email and password
 */
const passwordAuthApi = {
  login: async ({ email, password }: Credentials) => axios
    .post('/auth/login', {
      email,
      password,
    })
    .then((result) => result.data),
};

export default passwordAuthApi;
