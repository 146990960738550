const breakpoints = {
  mobile: 720,
  mobilePx: '720',
  tablet: 768,
  tabletPx: '768px',
  laptopSmall: 992,
  laptopSmallPx: '992px',
  desktop: 1366,
  desktopPx: '1366px',
  desktopLarge: 1920,
  desktopLargePx: '1920px',
};

export default breakpoints;
