import React, { useContext } from 'react';
import { AuthContextProps, AuthContextType } from './AuthProvider.types';
import useAuthProvider from './useAuthProvider';

export const AuthContext = React.createContext<AuthContextType>(
  {
  } as AuthContextType,
);

export function AuthProvider({ children }: AuthContextProps) {
  const hook = useAuthProvider();

  return <AuthContext.Provider value={hook}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);
