enum InsightsAppPaths {
  Home = '/',
  Explorer = '/explore',
  Widgets = '/widgets',
  ThermalComfort = '/widgets/thermal-comfort',
  AirQuality = '/widgets/air-quality',
  SpaceUtilization = '/widgets/space-utilization',
  DeskOccupancy = '/widgets/space-utilization/desk-occupancy',
  VisualComfort = '/widgets/visual-comfort',
  IndoorTemperature = '/widgets/thermal-comfort/indoor-temperature',
  RelativeHumidity = '/widgets/air-quality/relative-humidity',
  LightLevels = '/widgets/visual-comfort/light-levels',
  Reports = '/reports',
  Service = '/service',
  Configure = '/configure',
  Settings = '/settings',
  Profile = '/profile',
  PerfAudit = '/perfaudit',
  Controls = '/controls',
  NotFound = '/*',
}

export default InsightsAppPaths;
