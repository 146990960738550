import React, { useMemo } from 'react';
import { closest, distance } from 'fastest-levenshtein';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import AppsSchema from '../../apps/Apps.schema';
import {
  NotFoundPageSuggestedStyled,
  NotFoundPageSuggestedUrlStyled,
  NotFoundPageWrapperStyled,
} from './NotFoundPage.styled';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation('notFound');

  const [, appPath, pagePath] = useMemo(() => window.location.pathname.split('/'), []);

  const findClosestAppBasename = useMemo(
    () => closest(appPath, AppsSchema.map((val) => val.basename)),
    [appPath],
  );

  const findClosestAppPath = useMemo(() => {
    if (!pagePath) {
      return '';
    }

    const closestApp = AppsSchema.find((val) => val.basename === findClosestAppBasename);

    const appRoutes = closestApp?.routes.map((route) => route.path) as string[];

    return closest(pagePath ?? '/', appRoutes ?? []);
  }, []);

  const suggestedUrl = useMemo(() => {
    if (distance(findClosestAppBasename, appPath) > 3) {
      return null;
    }

    if (pagePath && distance(findClosestAppPath, pagePath) > 4) {
      return null;
    }

    return `/${findClosestAppBasename}${findClosestAppPath}`;
  }, [appPath, pagePath]);

  if (!suggestedUrl) {
    return (
      <NotFoundPageWrapperStyled>
        <NotFoundPageSuggestedStyled>
          {t('notFound')}
        </NotFoundPageSuggestedStyled>
      </NotFoundPageWrapperStyled>
    );
  }

  const redirectToSuggested = () => {
    window.location.replace(suggestedUrl);
  };

  return (
    <NotFoundPageWrapperStyled>
      <NotFoundPageSuggestedStyled
        onClick={redirectToSuggested}
        role="link"
        tabIndex={0}
        onKeyPress={redirectToSuggested}
      >
        {t('didYouMean')}
        {' '}
        <NotFoundPageSuggestedUrlStyled>
          {suggestedUrl}
        </NotFoundPageSuggestedUrlStyled>
        {' '}
        ?
      </NotFoundPageSuggestedStyled>
    </NotFoundPageWrapperStyled>
  );
};

export default NotFoundPage;
