export enum MainLayoutThresholds {
  LEFT_SECTION_CONTAINER_MIN_WIDTH = 1000,
  INFO_SECTION_MIN_HEIGHT = 180,
  INFO_SECTION_MAX_HEIGHT = 1056,
  CHART_SECTION_MX_HEIGHT = 60,
}

export const commonReflexElementProps = {
  propagateDimensions: true,
  propagateDimensionsRate: 300,
  resizeHeight: true,
};
