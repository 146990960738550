import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { updateTokenInfoLS } from './utils';
import passwordAuthApi from '../../api/passwordAuth/passwordAuth.api';
import { PasswordLoginDataType } from './PasswordAuthProvider.types';
import { assertTokenResponse } from '../OTPAuthProvider/useOTPAuthProvider';
import { RestError } from '../../types';

/**
 * Password Auth Provider sets token to the localstorage
 * and validated if user is signed in using email and password
 */
const usePasswordAuthProvider = () => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(['login']);
  const [error, setError] = useState<RestError | undefined>();
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);

  const login = (incomingValues: PasswordLoginDataType) => {
    queryClient
      .fetchQuery(['login'], () => passwordAuthApi.login(incomingValues))
      .then((res) => {
        assertTokenResponse(res);
        updateTokenInfoLS(res.token, res.expiresIn);
      })
      .catch((err) => {
        setError(err);
        setIsAlertShown(Boolean(err));

        throw err;
      });
  };

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage'));
  };

  return {
    login,
    logout,
    isLoading: !!isFetching,
    error,
    isAlertShown,
    setIsAlertShown,
  };
};

export default usePasswordAuthProvider;
