import React, { useContext } from 'react';
import { OTPAuthContextProps, OTPAuthContextType } from './OTPAuthProvider.types';
import useOTPAuthProvider from './useOTPAuthProvider';

export const OTPAuthContext = React.createContext<OTPAuthContextType>(
  {
  } as OTPAuthContextType,
);

export function OTPAuthProvider({ children }: OTPAuthContextProps) {
  const hook = useOTPAuthProvider();

  return <OTPAuthContext.Provider value={hook}>{children}</OTPAuthContext.Provider>;
}

export const useOTPAuthContext = () => useContext(OTPAuthContext);
