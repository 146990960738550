import { useEffect, useState } from 'react';

/**
 * Auth Provider sets token to the localstorage and validated if user is signed in
 */
const useAuthProvider = () => {
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);

  const tryAutoLogin = () => {
    setIsSignedIn(Boolean(localStorage.getItem('token')));
  };

  useEffect(() => {
    window.addEventListener('storage', () => setIsSignedIn(Boolean(localStorage.getItem('token'))));

    return () => {
      window.removeEventListener('storage', () => setIsSignedIn(Boolean(localStorage.getItem('token'))));
    };
  }, []);

  return {
    tryAutoLogin,
    isSignedIn,
  };
};

export default useAuthProvider;
