import styled from 'styled-components';

export const NotFoundPageWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50%;
`;

export const NotFoundPageSuggestedStyled = styled.div`
`;

export const NotFoundPageSuggestedUrlStyled = styled.a`
  cursor: pointer;
  color: blue;
`;
